import Header from '../components/header';
import Partners from '../components/partners';
import Testimonial from '../components/testimonial';
import Contact from '../components/contact';
import Footer from '../components/footer';
import about from '../assets/img/about-us-about.png';
import rings from '../assets/img/about-us-rings.png';
import SEO from '../components/SEO';
import ogImage from '../assets/img/og-image-home.png';

function About() { 
  return (
    <>
      <SEO 
        title="Melodica Music Therapy | About"
        description="Trauma Informed Music Therapy and Training for bigs, littles and in-betweens in Brisbane, Australia."
        keywords="music therapy, trauma informed, Brisbane, children, development"
        ogImage={ogImage}
        ogUrl="https://melodica.com.au"
      />
      <Header></Header>
      { /* HERO ABOUT */ }
      <section className="hero-about pb-24 pt-12 lg:pb-52 lg:pt-28 xl:pb-64 2xl:pt-44">
        <div className="container mx-auto px-4 w-full"> 
        <div className="mx-auto text-center max-w-[1200px]">
            <h2 className="mb-4 text-2xl text-white uppercase lg:text-4xl">ABOUT</h2>
            <h1 className="font-bold mb-3 text-4xl text-white lg:mb-6 lg:text-6xl">Leaders in trauma informed music therapy and training programs tailored across the lifespan</h1>
            {/* <a href="#" className="btn btn-shadow btn-yellow font-bold inline-block px-6 py-3 rounded-full text-xl uppercase hover:bg-yellow-500 lg:px-10 lg:py-4">Get Startd Now</a> */}
          </div>
        </div>
      </section>
      <section className="about-about-us pb-10 pt-20 lg:pb-14 lg:pt-28"> 
        <div className="container mx-auto px-4"> 
          <div className="gap-6 grid lg:gap-12 lg:grid-cols-2"> 
            <div> 
              <img src={about} alt="" className="block w-full" />
            </div>                     
            <div className="lg:pr-10 lg:pt-10"> 
              <div className="lg:flex">
                <div>
                  {/* <h2 className="font-bold mb-2 text-2xl text-center text-red uppercase lg:text-left">ABOUT US</h2> */}
                  <h3 className="font-bold mb-4 text-3xl text-center text-green uppercase lg:text-5xl lg:text-left">ABOUT</h3>
                </div>
                <div className="hidden lg:block lg:flex-shrink-0">
                  <img src={rings} alt="" width={137} height={191} className="lg:h-auto lg:w-20 xl:w-28 2xl:w-36" />
                </div>
              </div>
              {/* <p className="mb-3 text-2xl text-center uppercase lg:text-3xl lg:text-left">The Neurosequential Model of Therapeutics</p> */}
              <p className="mb-3 roboto text-base text-center lg:text-left">Sarah Dunston is a Brisbane (Australia) based Registered Music Therapist (RMT) specialising in community work. She has worked in many different contexts across the life span including residential aged care, community mental health, adult disability, schools (Flexi, special and mainstream) and early childhood (capacity building and early intervention).</p>
              <p className="mb-3 roboto text-base text-center lg:text-left">She developed a special interest in trauma informed practice quite early in her career and it continues to be a driving force in all her work due to its focus on making all clients feel safe, seen and valued.</p>
              <p className="mb-12 roboto text-base text-center lg:text-left">Sarah began an MPhil on the topic of trauma informed early childhood music therapy in 2019 and this business is a result of this study and her ongoing work in this space.</p> 
              <h3 className="mb-3 text-2xl text-center lg:text-2xl lg:text-left">What is Music Therapy?</h3> 
              <p className="mb-2 roboto text-base text-center lg:text-left">Music Therapy can offer a unique pathway to healing, communication, self-determination, autonomy and self-expression. Music has the power to connect on a deep emotional level.</p>
              <p className="mb-8 roboto text-base text-center lg:text-left">Whether it’s a catchy pop tune, a soothing melody, or a stirring classical piece, music can move people like nothing else. Music Therapy is an allied health profession that harnesses the power of music to help people overcome challenges and improve their overall quality of life.</p>
              {/* <div className="text-center lg:text-left"><a href="#" className="btn btn-shadow btn-yellow font-bold inline-flex items-center px-6 py-3 rounded-full text-xl uppercase hover:bg-yellow-500 lg:px-10 lg:py-4">Get Startd Now </a></div> */}
              
            </div>                     
          </div>                 
        </div>             
      </section> 
      { /* PARTNERS */ }
      <Partners></Partners> 
      { /* TESTIMONIAL */ }
      {/* <section className="bg-graphic-lightblue pb-20 pt-28 lg:pb-32 lg:pt-44 xl:pb-36 xl:pt-60 2xl:pt-80"> 
        <Testimonial></Testimonial>
      </section>  */}
      { /* CONTACT */ }      
      <Contact></Contact>            
      { /* MAP */ }      
      {/* <section className="map-home"> 
        <iframe allowFullScreen frameBorder={0} src="https://cdn.bootstrapstudio.io/placeholders/map.html" width="100%" height={400} />             
      </section>             */}
      { /* FOOTER */ }                     
      <Footer></Footer>      
    </>
  );
}

export default About;