import Header from '../components/header';
import Contact from '../components/contact';
import Footer from '../components/footer';

function Gallery() { 
  return (
    <>
      <Header></Header>
      { /* HERO GALLERY */ }
      <section className="hero-gallery pb-24 pt-12 lg:pb-52 lg:pt-28 xl:pb-64 2xl:pt-44">
        <div className="container mx-auto px-4 w-full"> 
          <div className="mx-auto text-center max-w-[800px]">
            <h2 className="mb-4 text-2xl text-white uppercase lg:text-4xl">Gallery</h2>
            <h1 className="font-bold mb-3 text-4xl text-white lg:mb-6 lg:text-5xl">Professional Development for Teachers</h1>
            <p className="mb-6 text-base text-white lg:text-xl">A new direction for trauma informed music therapy </p><a href="#" className="btn btn-shadow btn-yellow font-bold inline-block px-10 py-3 rounded-full text-xl uppercase hover:bg-yellow-500 lg:px-16 lg:py-4">Enroll Now</a>
          </div>
        </div>
      </section>
      <section className="py-20 lg:py-28"> 
        <div className="container mx-auto px-4"> 
          <div>
            <h2 className="font-bold mb-2 text-2xl text-center text-red uppercase">Gallery</h2>
            <h3 className="font-bold mb-8 text-4xl text-center text-green uppercase lg:text-5xl">OUR MUSIC GALLERY</h3>
          </div>
          <div>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
              <div className="border border-solid relative square">
                <div className="absolute content h-full left-0 top-0 w-full">
                  <img alt="" src="https://pinegrow.com/placeholders/img19.jpg" className="h-full object-cover w-full" />
                </div>
              </div>
              <div className="border border-solid relative square">
                <div className="absolute content h-full left-0 top-0 w-full">
                  <img alt="" src="https://pinegrow.com/placeholders/img19.jpg" className="h-full object-cover w-full" />
                </div>
              </div>
              <div className="border border-solid relative square">
                <div className="absolute content h-full left-0 top-0 w-full">
                  <img alt="" src="https://pinegrow.com/placeholders/img19.jpg" className="h-full object-cover w-full" />
                </div>
              </div>
              <div className="border border-solid relative square">
                <div className="absolute content h-full left-0 top-0 w-full">
                  <img alt="" src="https://pinegrow.com/placeholders/img19.jpg" className="h-full object-cover w-full" />
                </div>
              </div>
              <div className="border border-solid relative square">
                <div className="absolute content h-full left-0 top-0 w-full">
                  <img alt="" src="https://pinegrow.com/placeholders/img19.jpg" className="h-full object-cover w-full" />
                </div>
              </div>
              <div className="border border-solid relative square">
                <div className="absolute content h-full left-0 top-0 w-full">
                  <img alt="" src="https://pinegrow.com/placeholders/img19.jpg" className="h-full object-cover w-full" />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 text-center">
            <a href="#" className="btn btn-shadow btn-yellow font-bold inline-flex items-center khand px-6 py-3 rounded-full text-xl uppercase hover:bg-yellow-500 lg:px-10 lg:py-4">View More Gallery </a>
          </div>                 
        </div>             
      </section>      
      { /* CONTACT */ }      
      <Contact></Contact>            
      { /* MAP */ }      
      <section className="map-home"> 
        <iframe allowFullScreen frameBorder={0} src="https://cdn.bootstrapstudio.io/placeholders/map.html" width="100%" height={400} />             
      </section>            
      { /* FOOTER */ }                     
      <Footer></Footer>      
    </>
  );
}

export default Gallery;