import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Services from './pages/services';
import Uplift from './pages/uplift';
import Courses from './pages/courses';
import Gallery from './pages/gallery';
import News from './pages/news';
import Contact from './pages/contact';
import './assets/css/styles.css';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route index element={<Home/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/services" element={<Services/>} />
          <Route path="/uplift" element={<Uplift/>} />
          <Route path="/courses" element={<Courses/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/news" element={<News/>} />
          <Route path="/contact" element={<Contact/>} />
        </Routes>
      </Router>                   
    </>
  );
}

export default App;
