import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords, ogImage, ogUrl, ogType = 'website' }) => {
    const getImageUrl = (image) => {
        if (typeof image === 'string' && image.startsWith('http')) {
          // If it's already a full URL, return it as is
          return image;
        } else {
          // If it's an imported image or relative path, construct the full URL
          const baseUrl = window.location.origin;
          return `${baseUrl}${image}`;
        }
      };
    return (
        <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content={ogType} />
        <meta property="og:url" content={ogUrl} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={getImageUrl(ogImage)} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={ogUrl} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={getImageUrl(ogImage)} />
        </Helmet>
    );
};

export default SEO;