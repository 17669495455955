import Header from '../components/header';
import Footer from '../components/footer';
import placeholder from '../assets/img/800x1400.png';

function News() { 
  return (
    <>
      <Header></Header>
      { /* HERO NEWS */ }
      <section className="hero-news pb-24 pt-12 lg:pb-52 lg:pt-28 xl:pb-64 2xl:pb-96 2xl:pt-44">
        <div className="container mx-auto px-4 w-full"> 
          <div className="mx-auto text-center max-w-[800px]">
            <h2 className="mb-4 text-2xl text-white uppercase lg:text-4xl">Gallery</h2>
            <h1 className="font-bold mb-3 text-4xl text-white lg:mb-6 lg:text-5xl">Professional Development for Teachers</h1>
            <p className="mb-6 text-base text-white lg:text-xl">A new direction for trauma informed music therapy </p>
          </div>
        </div>
      </section>
      <section className="py-20 lg:py-28"> 
        <div className="container mx-auto px-4"> 
          <div>
            <h2 className="font-bold mb-2 text-2xl text-red uppercase">TOP NEWS &amp; INSIGHTS</h2>
            <h3 className="font-bold mb-8 text-4xl text-green uppercase lg:text-5xl">LATTEST NEWS &amp; BLOGS</h3>
          </div>
          <div>
            <div className="gap-8 grid lg:gap-16">
              <div>
                <div className="gap-8 grid lg:grid-cols-3">
                  <div>
                    <img alt="" src={placeholder} width={280} height={280} className="block object-cover rounded-2xl shadow-xl w-full" />
                  </div>
                  <div className="lg:col-span-2">
                    <div className="mb-2"><span className="bg-yellow-300 font-medium inline-block pt-1 px-4 rounded-full uppercase text-[#5f176e]">MUSIC THEREPY</span>
                    </div>
                    <h2 className="font-bold larsseit mb-2 text-black text-xl">Trauma Informed group music therapy program designed </h2>
                    <p className="mb-2 roboto text-xs text-[#a7a7b7]">Posted on May 1, 2020 In Articles </p>
                    <p className="line-clamp-6 mb-2 roboto text-sm">In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.&nbsp;In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.</p>
                    <a href className="font-bold italic roboto text-[#631a86] text-sm underline">READ THE FULL STORY</a>
                  </div>
                </div>
              </div>
              <div>
                <div className="gap-8 grid lg:grid-cols-3">
                  <div>
                    <img alt="" src={placeholder} width={280} height={280} className="block object-cover rounded-2xl shadow-xl w-full" />
                  </div>
                  <div className="lg:col-span-2">
                    <div className="mb-2"><span className="bg-yellow-300 font-medium inline-block pt-1 px-4 rounded-full uppercase text-[#5f176e]">MUSIC THEREPY</span>
                    </div>
                    <h2 className="font-bold larsseit mb-2 text-black text-xl">Trauma Informed group music therapy program designed </h2>
                    <p className="mb-2 roboto text-xs text-[#a7a7b7]">Posted on May 1, 2020 In Articles </p>
                    <p className="line-clamp-6 mb-2 roboto text-sm">In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.&nbsp;In this half day training, I will show up to ten registered teachers all they need to know to implement a trauma informed music based protocol that can be used on its own as a stand alone program, or embedded into the day to day life of a classroom. Also included is information on how trauma impacts the brains and behaviour of children, based on research.</p>
                    <a href className="font-bold italic roboto text-[#631a86] text-sm underline">READ THE FULL STORY</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 text-center">
            <a href="#" className="bg-[#e2e2e2] btn-shadow font-bold inline-flex items-center khand px-10 py-4 rounded-full text-2xl text-[#444444] uppercase lg:px-16 lg:py-5 hover:bg-[#d5d2d2]">Load More <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="34px" height="34px" className="ml-2"> 
                <path fillRule="evenodd" strokeWidth="2px" stroke="rgb(255, 255, 255)" fill="rgb(0, 0, 0)" d="M5.499,16.999 C5.499,10.648 10.648,5.499 16.999,5.499 C23.351,5.499 28.499,10.648 28.499,16.999 C28.499,23.351 23.351,28.499 16.999,28.499 C10.648,28.499 5.499,23.351 5.499,16.999 Z" /> 
                <path fillRule="evenodd" fill="rgb(248, 248, 249)" d="M14.999,12.999 L14.999,20.999 L20.999,16.999 L14.999,12.999 Z" /> 
              </svg></a>
          </div>                 
        </div>             
      </section>            
      { /* FOOTER */ }                     
      <Footer></Footer>      
    </>
  );
}

export default News;