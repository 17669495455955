import React, { useState } from 'react';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);
    try {
      console.log('Submitting form:', formData);
      const response = await fetch('https://api.melodica.com.au/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setSubmitStatus('success');
        setFormData({ name: '', email: '', phoneNumber: '', message: '' });
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    }
    setIsSubmitting(false);
  };

  return (
    <section className="contact-home py-14 lg:py-20"> 
      <div className="container mx-auto px-4"> 
        <div className="gap-y-6 grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-3"> 
          <div className="lg:col-start-1 lg:pr-10 lg:row-span-3 lg:row-start-1 xl:pr-20"> 
            <div className="mx-auto md:max-w-xl lg:max-w-full">
              <div className="bg-lightblue border-8 border-solid border-white p-6 rounded-2xl shadow-xl lg:p-10"> 
                {/* <h2 className="font-bold mb-2 text-2xl text-white uppercase lg:text-4xl">Send A Message</h2>  */}
                {submitStatus === 'success' && (
                  <div className="mb-4 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
                    Your message has been sent successfully!
                  </div>
                )}
                {submitStatus === 'error' && (
                  <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
                    There was an error sending your message. Please try again.
                  </div>
                )}
                <form onSubmit={handleSubmit}> 
                  <div className="mb-4"> 
                    <input 
                      className="appearance-none font-bold outline-none placeholder-inherit px-5 py-4 roboto text-sm w-full" 
                      placeholder="Name" 
                      required={true}
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    /> 
                  </div>                                     
                  <div className="mb-4"> 
                    <input 
                      className="appearance-none font-bold outline-none placeholder-inherit px-5 py-4 roboto text-sm w-full" 
                      placeholder="Email" 
                      type="email" 
                      required={true}
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    /> 
                  </div>
                  <div className="mb-4"> 
                    <input 
                      className="appearance-none font-bold outline-none placeholder-inherit px-5 py-4 roboto text-sm w-full" 
                      placeholder="Phone" 
                      type="text" 
                      required={true}
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    /> 
                  </div>                                     
                  <div className="mb-4"> 
                    <textarea 
                      rows={6} 
                      className="appearance-none font-bold outline-none placeholder-inherit px-5 py-4 roboto text-sm w-full" 
                      placeholder="Message" 
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea> 
                  </div>
                  <div className="grid mb-6">
                    <button 
                      type="submit" 
                      className="btn btn-shadow btn-yellow flex font-bold items-center justify-center px-6 py-3 text-xl uppercase hover:bg-yellow-500 lg:px-10 lg:py-4"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Sending...' : 'Send'}
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="34px" height="34px" className="ml-3"> 
                        <path fillRule="evenodd" strokeWidth="2px" stroke="rgb(255, 255, 255)" fill="rgb(0, 0, 0)" d="M5.499,16.999 C5.499,10.648 10.648,5.499 16.999,5.499 C23.351,5.499 28.499,10.648 28.499,16.999 C28.499,23.351 23.351,28.499 16.999,28.499 C10.648,28.499 5.499,23.351 5.499,16.999 Z" />                                                 
                        <path fillRule="evenodd" fill="rgb(248, 248, 249)" d="M14.999,12.999 L14.999,20.999 L20.999,16.999 L14.999,12.999 Z" />                                                 
                      </svg>
                    </button> 
                  </div>                                     
                </form>                                 
              </div>
            </div>
          </div>                     
          <div className="row-start-1 lg:pt-10"> 
            <h2 className="font-bold mb-2 roboto text-2xl text-center text-yellow uppercase lg:text-left">CONTACT</h2>
            <h3 className="font-bold mb-1 text-3xl text-center text-white uppercase lg:text-5xl lg:text-left">Send a Message</h3>
          </div>
          <div className="lg:row-span-2"> 
            <div className="flex items-center">
              <div className="mr-4"><span className="fa-2x fa-stack"><i className="fas fa-circle fa-stack-2x text-[#8fcedc]" /><i className="fa-inverse  fa-phone-alt fa-stack-1x fa-xs fas text-[#631a86]" /></span>
              </div>
              <div className="flex-1 flex-grow">
                <h6 className="font-semibold text-sm text-white lg:text-lg">PHONE</h6>
                <h5 className="font-semibold lg:text-2xl text-base text-white uppercase">0452 391 605</h5>
              </div>
            </div>
            <div className="flex items-center mt-8">
              <div className="mr-4"><span className="fa-2x fa-stack"><i className="fas fa-circle fa-stack-2x text-[#8fcedc]" /><i className="fa-inverse  fa-map-marker-alt fa-stack-1x fa-xs fas text-[#631a86]" /></span>
              </div>
              <div className="flex-1 flex-grow">
                <h6 className="font-semibold text-sm text-white lg:text-lg">LOCATION</h6>
                <h5 className="font-semibold lg:text-2xl text-base text-white uppercase">Brisbane, QLD, Australia, 4000</h5>
              </div>
            </div>
            <div className="flex items-center mt-8">
              <div className="mr-4"><span className="fa-2x fa-stack"><i className="fas fa-circle fa-stack-2x text-[#8fcedc]" /><i className="fa-envelope fa-inverse  fa-stack-1x fa-xs fas text-[#631a86]" /></span>
              </div>
              <div className="flex-1 flex-grow">
                <h6 className="font-semibold text-sm text-white lg:text-lg">EMAIL</h6>
                <h5 className="font-semibold text-base text-white uppercase lg:text-2xl">info@melodica.com.au</h5>
              </div>
            </div>                         
          </div>                     
        </div>                 
      </div>             
    </section>       
  );
}

export default Contact;