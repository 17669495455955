import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import avatar from '../assets/img/800x1400.png';

function Testimonial() {
    return (
        <>
        <div className="container mx-auto px-4">
          <div>
            <h2 className="font-bold mb-2 text-2xl text-center text-red uppercase">Reviews</h2>
            <h3 className="font-bold mb-8 text-4xl text-center text-green uppercase lg:text-5xl">WHAT DO OUR CLIENTS SAY?</h3>
          </div>
          { /* TESTIMONIALS SLIDER */ }
          <div>
            <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            breakpoints={{
              576: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              992: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
            }}
            className="mySwiper"          
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)} >
              
              <SwiperSlide>
              <div className="bg-white card-review flex flex-col grid-rows-3 h-[450px] overflow-hidden rounded-xl">
                <div className="pt-6 px-4 shrink-0">
                  <div className="flex items-center">
                    <div className="flex-auto">
                      <div className="flex items-center">
                        <div className="mr-4">
                          <img src={avatar} width={60} height={60} className="h-14 object-cover rounded-full w-14" />
                        </div>
                        <div className="flex-grow">
                          <h5 className="font-bold roboto text-gray-900">Jahnavi Ma</h5>
                          <div className="flex items-center">
                            <div>
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                            </div>
                            <div>
                              <span className="font-bold ml-2 roboto text-sm">5.0</span>
                            </div>
                          </div>
                          <h6 className="font-bold roboto text-xs">Nov 19, 2023</h6>
                        </div>
                      </div>
                    </div>
                    <div className="shrink-0">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30px" height="30px">
                        <image x="0px" y="0px" width="30px" height="30px" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAAB3RJTUUH6AgQDw0vQ4krOAAABGdJREFUSMetl39sU1UUxz/3vvfoRssG2/Kchk0D/EFMCA5sJUYxUlA0wY2sZoS/xPiHhBAjUUkafiQGhGCIPyL/mJhoxB9NZkDkD3B7uIQg2EYWNCrir4iZYYXh3LrRvrbv+sdryyht182dv17ePfd8zz3n3HO+VyilqEbiwYAONAFrgA7gfsAH/A2cAT4DzplWdLQae2Iy4HgwMDcH0gk8CrQCHkBOUHOAUeAS8DlwHPjOtKJljVcEjgcDa4FXcoBTlUPA26YVvVQ1cDwYqAd2Ay9OA3Ci/A68Chw2rWh24oIs1owHAy3ABzMACrAA2AzcdrpbgOPBQBPwDtA+A6AAp4BO04o6xQt6/mN4070CfLuApyoYGgR6cYvnZ+AG0Agsw630FcDsnG4vsMG0okOlDBVyPLAw2G60Jo6iqRKBAeBDYLdpRf8o51U8GFgHvIF7xdpNK/pPOV2hlCJ7al7dyCfNfakLDW1iloOoyYASeZ0ssN20ogeriW08GLgLKW2z59y1Snou8DltnZPUjt041cyNEy2gKYQ3A4oMsNO0ovurzGnVItM9NYYzqncJI4uv4y/mPHcRWW+jxnRQwqI5cWCmQQF0pJiPclaocQ3HlnjariObbMaPtSTSv8x7/Y6PfnTKbQ6+NgYgqocDQFlhL8Lu9byAcvYC3nxRCU8WZesXEGKNEUxeLQMqgGdxr56NWwvVSAqI6Cj1GG7vLfiubA2kOiNqsokKBjTgYWDdNCLtk6AWlQyXIy6rf+uTFTY7wPg0QLPAAlluVQEZvXprU5Q5EsSvlGgZhnDurtWGaytsluRTNHUZ1EF8CeoRJrRPQ2SwlfGghvRq5cPpAD8B3+O2zgw3U5Y/SCswv2hfBugXdm/NPahsD7BIEwopM/yWrmffaFvibPrOjh82fGyVcztX2ZLSTXY2bpvtKPp/HXhG2D0eA9T7hkxvRCh6ki3sGV3GgDObemGfXM7Qk+92fVX2LldwajHwDVBXtNQPrJLG6mTakJlPR5TBocQSnh9eyUDWS4NIISHYT+P2aeZxJy4nK5Y+YES6X2ZfeGTF+YOJpXhEhkaZRLnp0oE9/kjopSmddm9qD6iNlCAaQLcV9jqFsSgPb26/T7921BAOTukueBjYFevqLjsWl56sWei9fGBfbXzT09Lx4WiJYuzjQMgKe1MF4C1HHhIxu/ktBVsrHGYQsHIGLuJWcwOwGMQTiPRKJTLmrMQD1F7Zgkg3oLRxEIqc7uNW2HsaisiePxJqAt6jMgupIALIovQRZHIBtVe2YowtQenjCpxtVtj3ZkGzmGX6I6H5/C/eJQCB0kaQWS+eoY14rnfu7d3Bjlu0StFbfyRUh0tvt00PPOeASI05xtXd364/ext7qUjo/ZHQWuBlYNU0kL9AaftjGyJfl3RrsieMPxKaC/iB9dx8wtQUYup2rfyk+hOXXR4Bzse6usuO1UmBJzigC2hUsDrnxHLcBnEFiAHHBOK0Qg3HuronJQX/AW4yojl2S4jBAAAAAElFTkSuQmCC" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-end-2 col-start-1 flex-auto overflow-y-auto pb-6 pt-4 px-4 row-end-4 row-start-2">
                  <p className="mb-3 roboto text-center text-sm">I had a great opportunity to have an afternoon the reputed drumming session with Sarah at her home. It was great and I realised after the session how powerful it had been. I experienced the benefit of drumming in my communication and movements, it was like I became more aware of my rythem in my walking and connection with the earth. These are areas where I had weaknesses but I feel it would be beneficial in many other ways, each different for each individual depending on what they need. Sarah guided the session in a safe and reassuring way first playing the beat herself.&nbsp;I had a great opportunity to have an afternoon the reputed drumming session with Sarah at her home. It was great and I realised after the session how powerful it had been. I experienced the benefit of drumming in my communication and movements, it was like I became more aware of my rythem in my walking and connection with the earth. These are areas where I had weaknesses but I feel it would be beneficial in many other ways, each different for each individual depending on what they need. Sarah guided the session in a safe and reassuring way first playing the beat herself.</p>
                </div>                                 
              </div>              
              </SwiperSlide>
              <SwiperSlide>
              <div className="bg-white card-review flex flex-col grid-rows-3 h-[450px] overflow-hidden rounded-xl">
                <div className="pt-6 px-4 shrink-0">
                  <div className="flex items-center">
                    <div className="flex-auto">
                      <div className="flex items-center">
                        <div className="mr-4">
                          <img src={avatar} width={60} height={60} className="h-14 object-cover rounded-full w-14" />
                        </div>
                        <div className="flex-grow">
                          <h5 className="font-bold roboto text-gray-900">Jahnavi Ma</h5>
                          <div className="flex items-center">
                            <div>
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                            </div>
                            <div>
                              <span className="font-bold ml-2 roboto text-sm">5.0</span>
                            </div>
                          </div>
                          <h6 className="font-bold roboto text-xs">Nov 19, 2023</h6>
                        </div>
                      </div>
                    </div>
                    <div className="shrink-0">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30px" height="30px">
                        <image x="0px" y="0px" width="30px" height="30px" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAAB3RJTUUH6AgQDw0vQ4krOAAABGdJREFUSMetl39sU1UUxz/3vvfoRssG2/Kchk0D/EFMCA5sJUYxUlA0wY2sZoS/xPiHhBAjUUkafiQGhGCIPyL/mJhoxB9NZkDkD3B7uIQg2EYWNCrir4iZYYXh3LrRvrbv+sdryyht182dv17ePfd8zz3n3HO+VyilqEbiwYAONAFrgA7gfsAH/A2cAT4DzplWdLQae2Iy4HgwMDcH0gk8CrQCHkBOUHOAUeAS8DlwHPjOtKJljVcEjgcDa4FXcoBTlUPA26YVvVQ1cDwYqAd2Ay9OA3Ci/A68Chw2rWh24oIs1owHAy3ABzMACrAA2AzcdrpbgOPBQBPwDtA+A6AAp4BO04o6xQt6/mN4070CfLuApyoYGgR6cYvnZ+AG0Agsw630FcDsnG4vsMG0okOlDBVyPLAw2G60Jo6iqRKBAeBDYLdpRf8o51U8GFgHvIF7xdpNK/pPOV2hlCJ7al7dyCfNfakLDW1iloOoyYASeZ0ssN20ogeriW08GLgLKW2z59y1Snou8DltnZPUjt041cyNEy2gKYQ3A4oMsNO0ovurzGnVItM9NYYzqncJI4uv4y/mPHcRWW+jxnRQwqI5cWCmQQF0pJiPclaocQ3HlnjariObbMaPtSTSv8x7/Y6PfnTKbQ6+NgYgqocDQFlhL8Lu9byAcvYC3nxRCU8WZesXEGKNEUxeLQMqgGdxr56NWwvVSAqI6Cj1GG7vLfiubA2kOiNqsokKBjTgYWDdNCLtk6AWlQyXIy6rf+uTFTY7wPg0QLPAAlluVQEZvXprU5Q5EsSvlGgZhnDurtWGaytsluRTNHUZ1EF8CeoRJrRPQ2SwlfGghvRq5cPpAD8B3+O2zgw3U5Y/SCswv2hfBugXdm/NPahsD7BIEwopM/yWrmffaFvibPrOjh82fGyVcztX2ZLSTXY2bpvtKPp/HXhG2D0eA9T7hkxvRCh6ki3sGV3GgDObemGfXM7Qk+92fVX2LldwajHwDVBXtNQPrJLG6mTakJlPR5TBocQSnh9eyUDWS4NIISHYT+P2aeZxJy4nK5Y+YES6X2ZfeGTF+YOJpXhEhkaZRLnp0oE9/kjopSmddm9qD6iNlCAaQLcV9jqFsSgPb26/T7921BAOTukueBjYFevqLjsWl56sWei9fGBfbXzT09Lx4WiJYuzjQMgKe1MF4C1HHhIxu/ktBVsrHGYQsHIGLuJWcwOwGMQTiPRKJTLmrMQD1F7Zgkg3oLRxEIqc7uNW2HsaisiePxJqAt6jMgupIALIovQRZHIBtVe2YowtQenjCpxtVtj3ZkGzmGX6I6H5/C/eJQCB0kaQWS+eoY14rnfu7d3Bjlu0StFbfyRUh0tvt00PPOeASI05xtXd364/ext7qUjo/ZHQWuBlYNU0kL9AaftjGyJfl3RrsieMPxKaC/iB9dx8wtQUYup2rfyk+hOXXR4Bzse6usuO1UmBJzigC2hUsDrnxHLcBnEFiAHHBOK0Qg3HuronJQX/AW4yojl2S4jBAAAAAElFTkSuQmCC" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-end-2 col-start-1 flex-auto overflow-y-auto pb-6 pt-4 px-4 row-end-4 row-start-2">
                  <p className="mb-3 roboto text-center text-sm">I had a great opportunity to have an afternoon the reputed drumming session with Sarah at her home. It was great and I realised after the session how powerful it had been. I experienced the benefit of drumming in my communication and movements, it was like I became more aware of my rythem in my walking and connection with the earth. These are areas where I had weaknesses but I feel it would be beneficial in many other ways, each different for each individual depending on what they need. Sarah guided the session in a safe and reassuring way first playing the beat herself.&nbsp;I had a great opportunity to have an afternoon the reputed drumming session with Sarah at her home. It was great and I realised after the session how powerful it had been. I experienced the benefit of drumming in my communication and movements, it was like I became more aware of my rythem in my walking and connection with the earth. These are areas where I had weaknesses but I feel it would be beneficial in many other ways, each different for each individual depending on what they need. Sarah guided the session in a safe and reassuring way first playing the beat herself.</p>
                </div>                                 
              </div>              
              </SwiperSlide>
              <SwiperSlide>
              <div className="bg-white card-review flex flex-col grid-rows-3 h-[450px] overflow-hidden rounded-xl">
                <div className="pt-6 px-4 shrink-0">
                  <div className="flex items-center">
                    <div className="flex-auto">
                      <div className="flex items-center">
                        <div className="mr-4">
                          <img src={avatar} width={60} height={60} className="h-14 object-cover rounded-full w-14" />
                        </div>
                        <div className="flex-grow">
                          <h5 className="font-bold roboto text-gray-900">Jahnavi Ma</h5>
                          <div className="flex items-center">
                            <div>
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                            </div>
                            <div>
                              <span className="font-bold ml-2 roboto text-sm">5.0</span>
                            </div>
                          </div>
                          <h6 className="font-bold roboto text-xs">Nov 19, 2023</h6>
                        </div>
                      </div>
                    </div>
                    <div className="shrink-0">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30px" height="30px">
                        <image x="0px" y="0px" width="30px" height="30px" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAAB3RJTUUH6AgQDw0vQ4krOAAABGdJREFUSMetl39sU1UUxz/3vvfoRssG2/Kchk0D/EFMCA5sJUYxUlA0wY2sZoS/xPiHhBAjUUkafiQGhGCIPyL/mJhoxB9NZkDkD3B7uIQg2EYWNCrir4iZYYXh3LrRvrbv+sdryyht182dv17ePfd8zz3n3HO+VyilqEbiwYAONAFrgA7gfsAH/A2cAT4DzplWdLQae2Iy4HgwMDcH0gk8CrQCHkBOUHOAUeAS8DlwHPjOtKJljVcEjgcDa4FXcoBTlUPA26YVvVQ1cDwYqAd2Ay9OA3Ci/A68Chw2rWh24oIs1owHAy3ABzMACrAA2AzcdrpbgOPBQBPwDtA+A6AAp4BO04o6xQt6/mN4070CfLuApyoYGgR6cYvnZ+AG0Agsw630FcDsnG4vsMG0okOlDBVyPLAw2G60Jo6iqRKBAeBDYLdpRf8o51U8GFgHvIF7xdpNK/pPOV2hlCJ7al7dyCfNfakLDW1iloOoyYASeZ0ssN20ogeriW08GLgLKW2z59y1Snou8DltnZPUjt041cyNEy2gKYQ3A4oMsNO0ovurzGnVItM9NYYzqncJI4uv4y/mPHcRWW+jxnRQwqI5cWCmQQF0pJiPclaocQ3HlnjariObbMaPtSTSv8x7/Y6PfnTKbQ6+NgYgqocDQFlhL8Lu9byAcvYC3nxRCU8WZesXEGKNEUxeLQMqgGdxr56NWwvVSAqI6Cj1GG7vLfiubA2kOiNqsokKBjTgYWDdNCLtk6AWlQyXIy6rf+uTFTY7wPg0QLPAAlluVQEZvXprU5Q5EsSvlGgZhnDurtWGaytsluRTNHUZ1EF8CeoRJrRPQ2SwlfGghvRq5cPpAD8B3+O2zgw3U5Y/SCswv2hfBugXdm/NPahsD7BIEwopM/yWrmffaFvibPrOjh82fGyVcztX2ZLSTXY2bpvtKPp/HXhG2D0eA9T7hkxvRCh6ki3sGV3GgDObemGfXM7Qk+92fVX2LldwajHwDVBXtNQPrJLG6mTakJlPR5TBocQSnh9eyUDWS4NIISHYT+P2aeZxJy4nK5Y+YES6X2ZfeGTF+YOJpXhEhkaZRLnp0oE9/kjopSmddm9qD6iNlCAaQLcV9jqFsSgPb26/T7921BAOTukueBjYFevqLjsWl56sWei9fGBfbXzT09Lx4WiJYuzjQMgKe1MF4C1HHhIxu/ktBVsrHGYQsHIGLuJWcwOwGMQTiPRKJTLmrMQD1F7Zgkg3oLRxEIqc7uNW2HsaisiePxJqAt6jMgupIALIovQRZHIBtVe2YowtQenjCpxtVtj3ZkGzmGX6I6H5/C/eJQCB0kaQWS+eoY14rnfu7d3Bjlu0StFbfyRUh0tvt00PPOeASI05xtXd364/ext7qUjo/ZHQWuBlYNU0kL9AaftjGyJfl3RrsieMPxKaC/iB9dx8wtQUYup2rfyk+hOXXR4Bzse6usuO1UmBJzigC2hUsDrnxHLcBnEFiAHHBOK0Qg3HuronJQX/AW4yojl2S4jBAAAAAElFTkSuQmCC" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-end-2 col-start-1 flex-auto overflow-y-auto pb-6 pt-4 px-4 row-end-4 row-start-2">
                  <p className="mb-3 roboto text-center text-sm">I had a great opportunity to have an afternoon the reputed drumming session with Sarah at her home. It was great and I realised after the session how powerful it had been. I experienced the benefit of drumming in my communication and movements, it was like I became more aware of my rythem in my walking and connection with the earth. These are areas where I had weaknesses but I feel it would be beneficial in many other ways, each different for each individual depending on what they need. Sarah guided the session in a safe and reassuring way first playing the beat herself.&nbsp;I had a great opportunity to have an afternoon the reputed drumming session with Sarah at her home. It was great and I realised after the session how powerful it had been. I experienced the benefit of drumming in my communication and movements, it was like I became more aware of my rythem in my walking and connection with the earth. These are areas where I had weaknesses but I feel it would be beneficial in many other ways, each different for each individual depending on what they need. Sarah guided the session in a safe and reassuring way first playing the beat herself.</p>
                </div>                                 
              </div>              
              </SwiperSlide>
              <SwiperSlide>
              <div className="bg-white card-review flex flex-col grid-rows-3 h-[450px] overflow-hidden rounded-xl">
                <div className="pt-6 px-4 shrink-0">
                  <div className="flex items-center">
                    <div className="flex-auto">
                      <div className="flex items-center">
                        <div className="mr-4">
                          <img src={avatar} width={60} height={60} className="h-14 object-cover rounded-full w-14" />
                        </div>
                        <div className="flex-grow">
                          <h5 className="font-bold roboto text-gray-900">Jahnavi Ma</h5>
                          <div className="flex items-center">
                            <div>
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                            </div>
                            <div>
                              <span className="font-bold ml-2 roboto text-sm">5.0</span>
                            </div>
                          </div>
                          <h6 className="font-bold roboto text-xs">Nov 19, 2023</h6>
                        </div>
                      </div>
                    </div>
                    <div className="shrink-0">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30px" height="30px">
                        <image x="0px" y="0px" width="30px" height="30px" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAAB3RJTUUH6AgQDw0vQ4krOAAABGdJREFUSMetl39sU1UUxz/3vvfoRssG2/Kchk0D/EFMCA5sJUYxUlA0wY2sZoS/xPiHhBAjUUkafiQGhGCIPyL/mJhoxB9NZkDkD3B7uIQg2EYWNCrir4iZYYXh3LrRvrbv+sdryyht182dv17ePfd8zz3n3HO+VyilqEbiwYAONAFrgA7gfsAH/A2cAT4DzplWdLQae2Iy4HgwMDcH0gk8CrQCHkBOUHOAUeAS8DlwHPjOtKJljVcEjgcDa4FXcoBTlUPA26YVvVQ1cDwYqAd2Ay9OA3Ci/A68Chw2rWh24oIs1owHAy3ABzMACrAA2AzcdrpbgOPBQBPwDtA+A6AAp4BO04o6xQt6/mN4070CfLuApyoYGgR6cYvnZ+AG0Agsw630FcDsnG4vsMG0okOlDBVyPLAw2G60Jo6iqRKBAeBDYLdpRf8o51U8GFgHvIF7xdpNK/pPOV2hlCJ7al7dyCfNfakLDW1iloOoyYASeZ0ssN20ogeriW08GLgLKW2z59y1Snou8DltnZPUjt041cyNEy2gKYQ3A4oMsNO0ovurzGnVItM9NYYzqncJI4uv4y/mPHcRWW+jxnRQwqI5cWCmQQF0pJiPclaocQ3HlnjariObbMaPtSTSv8x7/Y6PfnTKbQ6+NgYgqocDQFlhL8Lu9byAcvYC3nxRCU8WZesXEGKNEUxeLQMqgGdxr56NWwvVSAqI6Cj1GG7vLfiubA2kOiNqsokKBjTgYWDdNCLtk6AWlQyXIy6rf+uTFTY7wPg0QLPAAlluVQEZvXprU5Q5EsSvlGgZhnDurtWGaytsluRTNHUZ1EF8CeoRJrRPQ2SwlfGghvRq5cPpAD8B3+O2zgw3U5Y/SCswv2hfBugXdm/NPahsD7BIEwopM/yWrmffaFvibPrOjh82fGyVcztX2ZLSTXY2bpvtKPp/HXhG2D0eA9T7hkxvRCh6ki3sGV3GgDObemGfXM7Qk+92fVX2LldwajHwDVBXtNQPrJLG6mTakJlPR5TBocQSnh9eyUDWS4NIISHYT+P2aeZxJy4nK5Y+YES6X2ZfeGTF+YOJpXhEhkaZRLnp0oE9/kjopSmddm9qD6iNlCAaQLcV9jqFsSgPb26/T7921BAOTukueBjYFevqLjsWl56sWei9fGBfbXzT09Lx4WiJYuzjQMgKe1MF4C1HHhIxu/ktBVsrHGYQsHIGLuJWcwOwGMQTiPRKJTLmrMQD1F7Zgkg3oLRxEIqc7uNW2HsaisiePxJqAt6jMgupIALIovQRZHIBtVe2YowtQenjCpxtVtj3ZkGzmGX6I6H5/C/eJQCB0kaQWS+eoY14rnfu7d3Bjlu0StFbfyRUh0tvt00PPOeASI05xtXd364/ext7qUjo/ZHQWuBlYNU0kL9AaftjGyJfl3RrsieMPxKaC/iB9dx8wtQUYup2rfyk+hOXXR4Bzse6usuO1UmBJzigC2hUsDrnxHLcBnEFiAHHBOK0Qg3HuronJQX/AW4yojl2S4jBAAAAAElFTkSuQmCC" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-end-2 col-start-1 flex-auto overflow-y-auto pb-6 pt-4 px-4 row-end-4 row-start-2">
                  <p className="mb-3 roboto text-center text-sm">I had a great opportunity to have an afternoon the reputed drumming session with Sarah at her home. It was great and I realised after the session how powerful it had been. I experienced the benefit of drumming in my communication and movements, it was like I became more aware of my rythem in my walking and connection with the earth. These are areas where I had weaknesses but I feel it would be beneficial in many other ways, each different for each individual depending on what they need. Sarah guided the session in a safe and reassuring way first playing the beat herself.&nbsp;I had a great opportunity to have an afternoon the reputed drumming session with Sarah at her home. It was great and I realised after the session how powerful it had been. I experienced the benefit of drumming in my communication and movements, it was like I became more aware of my rythem in my walking and connection with the earth. These are areas where I had weaknesses but I feel it would be beneficial in many other ways, each different for each individual depending on what they need. Sarah guided the session in a safe and reassuring way first playing the beat herself.</p>
                </div>                                 
              </div>              
              </SwiperSlide>
              <SwiperSlide>
              <div className="bg-white card-review flex flex-col grid-rows-3 h-[450px] overflow-hidden rounded-xl">
                <div className="pt-6 px-4 shrink-0">
                  <div className="flex items-center">
                    <div className="flex-auto">
                      <div className="flex items-center">
                        <div className="mr-4">
                          <img src={avatar} width={60} height={60} className="h-14 object-cover rounded-full w-14" />
                        </div>
                        <div className="flex-grow">
                          <h5 className="font-bold roboto text-gray-900">Jahnavi Ma</h5>
                          <div className="flex items-center">
                            <div>
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                              <i className="fa-star fas text-sm text-yellow-400" />
                            </div>
                            <div>
                              <span className="font-bold ml-2 roboto text-sm">5.0</span>
                            </div>
                          </div>
                          <h6 className="font-bold roboto text-xs">Nov 19, 2023</h6>
                        </div>
                      </div>
                    </div>
                    <div className="shrink-0">
                      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="30px" height="30px">
                        <image x="0px" y="0px" width="30px" height="30px" xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAAB3RJTUUH6AgQDw0vQ4krOAAABGdJREFUSMetl39sU1UUxz/3vvfoRssG2/Kchk0D/EFMCA5sJUYxUlA0wY2sZoS/xPiHhBAjUUkafiQGhGCIPyL/mJhoxB9NZkDkD3B7uIQg2EYWNCrir4iZYYXh3LrRvrbv+sdryyht182dv17ePfd8zz3n3HO+VyilqEbiwYAONAFrgA7gfsAH/A2cAT4DzplWdLQae2Iy4HgwMDcH0gk8CrQCHkBOUHOAUeAS8DlwHPjOtKJljVcEjgcDa4FXcoBTlUPA26YVvVQ1cDwYqAd2Ay9OA3Ci/A68Chw2rWh24oIs1owHAy3ABzMACrAA2AzcdrpbgOPBQBPwDtA+A6AAp4BO04o6xQt6/mN4070CfLuApyoYGgR6cYvnZ+AG0Agsw630FcDsnG4vsMG0okOlDBVyPLAw2G60Jo6iqRKBAeBDYLdpRf8o51U8GFgHvIF7xdpNK/pPOV2hlCJ7al7dyCfNfakLDW1iloOoyYASeZ0ssN20ogeriW08GLgLKW2z59y1Snou8DltnZPUjt041cyNEy2gKYQ3A4oMsNO0ovurzGnVItM9NYYzqncJI4uv4y/mPHcRWW+jxnRQwqI5cWCmQQF0pJiPclaocQ3HlnjariObbMaPtSTSv8x7/Y6PfnTKbQ6+NgYgqocDQFlhL8Lu9byAcvYC3nxRCU8WZesXEGKNEUxeLQMqgGdxr56NWwvVSAqI6Cj1GG7vLfiubA2kOiNqsokKBjTgYWDdNCLtk6AWlQyXIy6rf+uTFTY7wPg0QLPAAlluVQEZvXprU5Q5EsSvlGgZhnDurtWGaytsluRTNHUZ1EF8CeoRJrRPQ2SwlfGghvRq5cPpAD8B3+O2zgw3U5Y/SCswv2hfBugXdm/NPahsD7BIEwopM/yWrmffaFvibPrOjh82fGyVcztX2ZLSTXY2bpvtKPp/HXhG2D0eA9T7hkxvRCh6ki3sGV3GgDObemGfXM7Qk+92fVX2LldwajHwDVBXtNQPrJLG6mTakJlPR5TBocQSnh9eyUDWS4NIISHYT+P2aeZxJy4nK5Y+YES6X2ZfeGTF+YOJpXhEhkaZRLnp0oE9/kjopSmddm9qD6iNlCAaQLcV9jqFsSgPb26/T7921BAOTukueBjYFevqLjsWl56sWei9fGBfbXzT09Lx4WiJYuzjQMgKe1MF4C1HHhIxu/ktBVsrHGYQsHIGLuJWcwOwGMQTiPRKJTLmrMQD1F7Zgkg3oLRxEIqc7uNW2HsaisiePxJqAt6jMgupIALIovQRZHIBtVe2YowtQenjCpxtVtj3ZkGzmGX6I6H5/C/eJQCB0kaQWS+eoY14rnfu7d3Bjlu0StFbfyRUh0tvt00PPOeASI05xtXd364/ext7qUjo/ZHQWuBlYNU0kL9AaftjGyJfl3RrsieMPxKaC/iB9dx8wtQUYup2rfyk+hOXXR4Bzse6usuO1UmBJzigC2hUsDrnxHLcBnEFiAHHBOK0Qg3HuronJQX/AW4yojl2S4jBAAAAAElFTkSuQmCC" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="col-end-2 col-start-1 flex-auto overflow-y-auto pb-6 pt-4 px-4 row-end-4 row-start-2">
                  <p className="mb-3 roboto text-center text-sm">I had a great opportunity to have an afternoon the reputed drumming session with Sarah at her home. It was great and I realised after the session how powerful it had been. I experienced the benefit of drumming in my communication and movements, it was like I became more aware of my rythem in my walking and connection with the earth. These are areas where I had weaknesses but I feel it would be beneficial in many other ways, each different for each individual depending on what they need. Sarah guided the session in a safe and reassuring way first playing the beat herself.&nbsp;I had a great opportunity to have an afternoon the reputed drumming session with Sarah at her home. It was great and I realised after the session how powerful it had been. I experienced the benefit of drumming in my communication and movements, it was like I became more aware of my rythem in my walking and connection with the earth. These are areas where I had weaknesses but I feel it would be beneficial in many other ways, each different for each individual depending on what they need. Sarah guided the session in a safe and reassuring way first playing the beat herself.</p>
                </div>                                 
              </div>              
              </SwiperSlide>
            </Swiper>            
          </div>                 
        </div>        
        </>
    );
  }
    
  export default Testimonial;