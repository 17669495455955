import uplift from '../assets/img/uplift.png';
import actforkids from '../assets/img/actforkids.png';
import music from '../assets/img/music.png';
import online from '../assets/img/online.png';

function Partners() {
    return (
        <>
        <section className="py-10 text-center text-gray-500 lg:py-20"> 
            <div className="container mx-auto px-4"> 
            <div className="max-w-5xl mx-auto">
                <div className="gap-4 grid grid-cols-4 items-center lg:gap-24"> 
                <div> 
                    <img src={uplift} width={187} height={33} />
                </div>
                <div> 
                    <img src={actforkids} width={187} height={33} />
                </div>
                <div> 
                    <img src={music} width={142} height={65} />
                </div>
                <div> 
                    <img src={online} width={178} height={46} />
                </div>                         
                </div>            
            </div>
            </div>             
        </section>        
        </>
    );
  }
    
  export default Partners;